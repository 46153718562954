// eslint-disable-next-line no-restricted-syntax
import { TextStyle, Application } from 'pixi.js';
import { Resizable } from '../interfaces/resizable';
import { ProgressBarView } from '../pixi/progress-bar-view';
import { ITransitioner } from './transitioner';
import FontFaceObserver from 'fontfaceobserver';
import gsap from 'gsap';

export class ProgressBarTransitioner implements ITransitioner, Resizable {
  private progressBarView: ProgressBarView;
  private font = new FontFaceObserver('Grandstander');
  private initialized = false;

  public constructor(private app: Application) {
    this.progressBarView = new ProgressBarView();
    this.progressBarView.alpha = 0;
    this.progressBarView.Text = 'Loading ...';
    this.refreshBarProgression = this.refreshBarProgression.bind(this);
  }

  async transition(...tasks: ((progression: (progress: number) => void) => Promise<void>)[]): Promise<void> {
    this.app.stage.addChild(this.progressBarView);
    this.progressBarView.zIndex = Number.MAX_SAFE_INTEGER;
    this.progressBarView.position.set(this.app.screen.width / 2, this.app.screen.height / 2);
    this.app.stage.setChildIndex(this.progressBarView, this.app.stage.children.length - 1);
    await this.initializeFont();
    this.progressBarView.alpha = 1;

    for (const task of tasks) {
      this.progressBarView.progress = 0;
      await task(this.refreshBarProgression);
    }

    await gsap.to(this.progressBarView, { alpha: 0, duration: 1 });
    if (this.app.stage) {
      this.app.stage.removeChild(this.progressBarView);
    }
  }

  private async initializeFont() {
    if (!this.initialized) {
      await this.font.load();
      this.progressBarView.configure({
        textStyle: new TextStyle({ fontFamily: 'Grandstander', fill: '#ffffff', fontSize: 20 }),
      });
      this.initialized = true;
    }
  }

  refreshBarProgression = (progression: number): void => {
    this.progressBarView.progress = progression;
  };

  resize(width: number, height: number) {
    this.progressBarView.position.set(width / 2, height / 2);
    this.progressBarView.setWidth(width);
    return this;
  }
}
