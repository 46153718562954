// eslint-disable-next-line no-restricted-syntax
import { Container, Graphics, Text, TextStyle } from 'pixi.js';
import gsap from 'gsap';
import { Vector2 } from '../math/vector';

export type ProgressBarSettings = {
  color: number;
  backgroundColor?: number;
  textStyle: TextStyle;
  height: number;
  radius: number;
};

export const defaultProgressBarSettings: ProgressBarSettings = {
  color: 0xff_ff_ff,
  textStyle: new TextStyle({
    fontFamily: 'Comic Sans MS',
    fill: '#ffffff',
    fontSize: 20,
    fontWeight: 'bold',
  }),
  height: 35,
  radius: 15,
};

type ProgressBarConfig = {
  progress: number;
};

export class ProgressBarView extends Container {
  private barBorder: Graphics = new Graphics();
  private bar: Graphics = new Graphics();
  private barAdjustPos = new Vector2(12, 10);

  private text: Text = new Text('');
  private settings!: ProgressBarSettings;
  private barWidth = 0;
  public get BarWidth() {
    return this.barWidth;
  }

  private config: ProgressBarConfig = {
    progress: 0,
  };

  public get progress(): number {
    return this.config.progress;
  }

  public set progress(value: number) {
    gsap.killTweensOf(this.config);
    gsap.to(this.config, { progress: value, duration: 0.1, onUpdate: () => this.drawProgress() });
  }

  public get Text() {
    return this.text.text;
  }
  public set Text(value: string) {
    this.text.text = value;
  }

  constructor(settings: Partial<ProgressBarSettings> = defaultProgressBarSettings) {
    super();

    this.addChild(this.barBorder);
    this.addChild(this.bar);
    this.addChild(this.text);
    this.configure(settings);
  }

  public configure(settings: Partial<ProgressBarSettings> = {}) {
    this.settings = { ...defaultProgressBarSettings, ...settings };
    this.text.style = this.settings.textStyle;
    this.text.anchor.set(0, 1);
  }

  private drawBorder(width: number) {
    this.barWidth = width;

    this.barBorder.clear();
    this.barBorder.lineStyle(4, this.settings.color, 1);
    if (this.settings.backgroundColor) this.barBorder.beginFill(this.settings.backgroundColor);
    this.barBorder.drawRoundedRect(-width / 2, 0, width, this.settings.height, this.settings.radius);
    this.barBorder.endFill();
  }

  private drawProgress(config: ProgressBarConfig = this.config) {
    this.bar.clear();
    this.bar.beginFill(this.settings.color);
    this.bar.drawRoundedRect(
      -this.barWidth / 2 + this.barAdjustPos.x / 2,
      this.barAdjustPos.y / 2,
      this.barWidth * config.progress - this.barAdjustPos.x,
      this.settings.height - this.barAdjustPos.y,
      this.settings.radius * 0.7
    );
    this.bar.endFill();
  }

  public setWidth(width: number) {
    this.drawBorder(width > 900 ? width / 2.5 : width / 1.3);
    this.drawProgress();
    this.text.position.set(-this.barBorder.width / 2 + 15, -5);
  }
}
